
//@ts-nocheck
import Vue from "vue";
import {
	Advertiser,
	Category
} from "@/interfaces/advertiser";
import Alertize from "@/components/Alertize.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import CardTextFieldToolTip from "@/components/Content/CardTextFieldToolTip.vue";
import {
	isRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isMaxCustomLength
} from "@/services/rule-services";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";
import CardActions from "@/components/Content/CardAction.vue";
import { isEmpty, isNull } from "lodash";
//@ts-check

const ACCOUNT = "rappi_amplify";
const MAKERS_NAMES_ENABLED = ["Otros", "Otro"];

export default Vue.extend({
	name: "AdvertiserCreate",
	props: {},
	components: {
		Alertize,
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		CardTextFieldToolTip,
		CardActions,
	},
	data: () => ({
		title: "Create",
		advertiser: {
			name: "",
			category_id: "",
			app_bundle: "",
			domain: "",
			active: true,
			maker_name: "",
    		country_id: 0
		},
		valid: true,
		show_tooltip_app_bundle: false,
		show_tooltip_domain: false,
		countries: [],
		makers: [],
		filters:{
			country_id: null
		}
	}),
	created() {},
	async mounted() {
		await this.setLoadingData(TypeLoading.loading);
		await this.dispatchCategories();
		await this.setLoadingData();
		if(this.isAccountRappi){
			await this.loadData();
		}
	},
	computed: {
		...mapGetters("profile", ["account"]),
		getCategories(): Category[] {
			return this.$store.state.advertiser.categories;
		},
		getCountrys(){
			return this.countries;
		},
		getMarkers(){	
			return this.makers;
		},
		getRules() {
			return {
				isRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isMaxCustomLength
			};
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
		isAccountRappi(){
			return this.account.account_type === ACCOUNT;
		},
		isDisabledName(){
			return !isEmpty(this.advertiser.maker_name) && !MAKERS_NAMES_ENABLED.includes(this.advertiser.maker_name);
		},
		disabledMakers(){
			return this.advertiser.country_id == 0 || isNull(this.advertiser.country_id);
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("person", ["fetchData"]),
		...mapActions("advertiser", ["makersList"]),
		...mapActions("proccess", ["setLoadingField"]),

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{ root: true }
			);
		},

		async loadData(){
			await this.fetchCountries();
		},

		async validate() {
			return await this.$refs.form.validate();
		},

		async handleAction(action: any) {
			switch (action.type) {
				case "cancel":
					this.handleCancel();
					break;
			}
		},

		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				await this.create();
				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},
		handleCancel() {
			this.$router.push({ name: "AdvertisersIndex" });
		},
		toggleTooltipAppBundle() {
			this.show_tooltip_app_bundle = !this.show_tooltip_app_bundle;
		},
		toggleTooltipDomain() {
			this.show_tooltip_domain = !this.show_tooltip_domain;
		},
		toggleStatus(status: boolean) {
			this.advertiser.active = Boolean(status);
		},
		async dispatchCategories() {
			return this.$store.dispatch("advertiser/getCategories", {
				root: true
			});
		},
		async fetchMakers(){
			if(!this.advertiser.country_id){
				return;
			}
			this.setLoadingField(true);
			this.filters.country_id = this.advertiser.country_id;
			await this.makersList({filters: this.filters})
			.then(async (resp) => {
				await this.parseDataMakers(resp);
				this.setLoadingField(false);
			})
			.catch(async (err) => {
				this.makers = [];
				this.setLoadingField(false);
			});
		},
		async fetchCountries(){
			let params = {
				type: "countries"
			};
			this.setLoadingField(true);
			await this.fetchData(params)
			.then(async (resp) => {
				await this.parseDataCountry(resp);
				this.setLoadingField(false);
			})
			.catch(async (err) => {
				this.setLoadingField(false);
			});
		},
		async parseDataCountry(countries: any){
			countries.forEach(c => {
				let {title, items} = c;
				this.countries.push({header: title});
				items.forEach(i => {
					let {id, value} = i;
					this.countries.push({group: title, id: id, value: value});
				});
			});
		},
		async parseDataMakers(makers: any){
			this.makers = [];
			makers.forEach(c => {
				let {maker} = c;
				this.makers.push({id: maker, value: maker});
			});
		},
		async create() {
			return this.$store.dispatch("advertiser/create", this.advertiser, {
				root: true
			});
		},
		async setData(value: string){
			this.advertiser.name = value;
		},
	},
	watch:{
		async "advertiser.maker_name"(old, val){
			await this.setData(old);
		},
		async "advertiser.country_id"(old, val){
			this.makers = [];
			this.advertiser.maker_name = "";
			this.advertiser.name = "";
		}
	}
});
